import React from 'react';
import { Column, Row } from 'components/ui/Grid';
import { BackgroundTitle, bgTitleSizes } from 'components/ui2/BackgroundTitle';
import Pagination from 'components/ui2/Pagination';
import Hr from 'components/ui/Hr';
import CardCategory, { ColumnCard } from './card';

const numberItem = 6;

const CategoryComponent = ({ title = '', list = [], redirectToCorsi }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentList, setCurrentList] = React.useState([]);

  const onSetCurrentList = () => {
    const firstItem = (currentPage - 1) * numberItem;
    const lastItem = firstItem + numberItem;

    setCurrentList(list.slice(firstItem, lastItem));
  };

  React.useEffect(() => {
    onSetCurrentList();
  }, [list]);

  React.useEffect(() => {
    onSetCurrentList();
  }, [currentPage]);

  return (
    <Row fluid padding="0 0 0 20px">
      <Column xs="12" padding="0">
        <BackgroundTitle size={bgTitleSizes.small} bgColor="primary" label={title} />
      </Column>
      {currentList.map((item, index) => (
        <ColumnCard
          key={index.toString()}
          item={item}
          index={index}
          redirectToCorsi={redirectToCorsi}
        />
      ))}
      <Row
        fluid
        flex
        justifycontent="center"
        padding="0"
        sizepadding={{ xs: '5rem 0 0 0', md: '3rem 0 0 0' }}
      >
        <Pagination
          json={[]}
          count={list.length || 0}
          currentPage={currentPage}
          numberitem={numberItem}
          setCurrentPage={setCurrentPage}
          navNumber={numberItem}
        />
      </Row>
    </Row>
  );
};

CategoryComponent.displayName = 'Category component';

const Category = React.memo(CategoryComponent);

export default Category;
