import React from 'react';
import { withRouter } from 'react-router';
import { PAGE_HOME_CORSI_ITALIANO } from 'types/url';
import Category from './category';
import { CategoryChoice } from '../../constants';

const LeftColumn = withRouter(({ dataset, history }) => {
  const redirectToCorsi = (idEnte, idArea) => {
    history.push(`${PAGE_HOME_CORSI_ITALIANO}/${dataset.idMunicipio || ' '}/${dataset.momentDay?.length ? dataset.momentDay.join(', ') : ' '}/${dataset.typePayment?.length ? dataset.typePayment.join(', ') : ' '}/${idEnte}/${idArea}`);
  };

  return (
    <React.Fragment>
      {
      Object.keys(dataset?.listCards).map((item) => {
        if (dataset?.selectedChoice === CategoryChoice.Tutti || dataset?.selectedChoice === item) {
          return (
            <Category
              title={item}
              list={dataset?.listCards[item]}
              key={`key-category${item}`}
              redirectToCorsi={redirectToCorsi}
            />
          );
        }

        return <></>;
      })
    }
    </React.Fragment>
  );
});

LeftColumn.displayName = 'Left Column Navigation';

export default LeftColumn;
