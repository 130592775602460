import React, { useEffect, useRef, useState } from 'react';
import { Column, Row } from 'components/ui/Grid';
import styled, { keyframes, css } from 'styled-components';
import { colors } from 'theme';
import Text from 'components/ui/Text';
import AnchorLink from 'components/ui/AnchorLink';
import FaIcon from 'components/ui2/FaIcon';
import Button from 'components/ui2/Button';
import ModaleSchedaEnte from 'components/shared/ModaleSchedaEnte';
import Hr from 'components/ui/Hr';

const StyledText = styled(Text)`
  border-radius: 10px 10px 10px 10px;
  border: 2px solid ${({ color }) => colors[color]};
  color: ${({ color }) => colors[color]};
  padding: 0.2rem;
  position: absolute;
  left: ${props => props.left};
  bottom: 2.3rem;
`;

const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const StyledTextTitleWrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  overflow: hidden;
`;

const StyledTextTitle = styled(Text)`
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  transition: transform 0.3s ease-out;
  cursor: pointer;
  :hover {
    animation: ${(props) => props.isTextWithHover ? css`${scrollAnimation} 10s linear infinite;` : 'none;'}
  }
`;

const StyledTextAddress = styled(Text)`
  display: inline-block;
  white-space: nowrap;
`;

const StyledColumnTitle = styled(Column)`
  display: flex;
  position: relative;
  align-items: center; /* Centra il testo verticalmente */
  overflow: hidden; /* Nasconde il testo che esce */
  white-space: nowrap;
`;

const StyledDivPartner = styled.div`
  position: relative;
`;

export const ColumnCard = ({ item, redirectToCorsi, index }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      const element = document.getElementById(`key-${item.name}-${index}`);
      if (element) {
        setWidth(element.offsetWidth); // Aggiorna lo stato per forzare il re-render
      }
    };

    updateWidth(); // Controlla la larghezza iniziale

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [item.name, index]);

  return (
    <Column
      id={`key-${item.name}-${index}`}
      xs="12"
      md="6"
      lg="4"
      padding="2rem 1rem 0 0"
      key={`key-${item.name}-${index}`}
    >
      <Hr width="100%" height="2px" color="primary" top="0px" bottom="2.5em" />
      {width && (
        <CardCategory
          item={item}
          redirectToCorsi={redirectToCorsi}
          columnWidth={width}
        />
      )}
    </Column>
  );
};

ColumnCard.displayName = 'Column Card';

const CardCategoryComponent = ({ item, redirectToCorsi, columnWidth }) => {
  const [modaleSchedaEnteData, setModaleSchedaEnteData] = React.useState(undefined);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(columnWidth < textRef.current.offsetWidth);
    }
  }, [columnWidth]);

  return (
    <React.Fragment>
      <Row fluid padding="0" display="flex">
        <StyledDivPartner>
          {item?.isPartner ? (
            <StyledText
              tag="span"
              weight="bold"
              value="PARTNER"
              color="blue"
              size="f8"
              left={columnWidth ? `${columnWidth - 75}px` : '9rem'}
            />
          ) : undefined}
          <StyledTextTitleWrapper width={`${columnWidth - 20}px`}>
            <span ref={textRef}>
              <StyledTextTitle
                tag="span"
                weight="bold"
                size="f6"
                value={item?.name || ''}
                onClick={() => setModaleSchedaEnteData(item?.idEnte)}
                isTextWithHover={isOverflowing}
              />
            </span>
          </StyledTextTitleWrapper>
        </StyledDivPartner>
        <StyledColumnTitle xs="12" padding="0 0 0.5rem 0">
          <StyledTextAddress tag="span" value={item?.address} />
        </StyledColumnTitle>
        <Column xs="1" padding="0 0 0.5rem 0">
          <Text tag="span" value="Tel." />
        </Column>
        <Column xs="10" padding="0 0 0.5rem 1rem">
          <AnchorLink
            weight="bold"
            // color={color}
            value={item?.number}
            to={`tel:${item?.number}`}
            textDecoration="none"
          />
        </Column>
        <Column xs="1" padding="0 0 0.5rem 0">
          <AnchorLink to={`mailto:${item?.email}`} textDecoration="none">
            <FaIcon icon="envelope" fontSize="f5" />
          </AnchorLink>
        </Column>
        <Column xs="12" padding="1rem 0 0 0">
          <Button
            color="primary"
            label="CORSI OFFERTI"
            onClick={() => redirectToCorsi(item.idEnte, item.idArea)}
          />
        </Column>
      </Row>
      <ModaleSchedaEnte
        idEnte={modaleSchedaEnteData}
        open={Boolean(modaleSchedaEnteData)}
        setOpen={() => setModaleSchedaEnteData(undefined)}
      />
    </React.Fragment>
  );
};

CardCategoryComponent.displayName = 'CardCategory component';

const CardCategory = React.memo(CardCategoryComponent);

export default CardCategory;
