import React from 'react';
import { withRouter } from 'react-router';
import Text from 'components/ui/Text';
import { useGraphQLRequest } from 'hooks/graphQLRequest/useGraphQLRequest';
import FaIcon from 'components/ui2/FaIcon';
import styled from 'styled-components';
import FormFilterCorsiItaliano from './filterForm';
import FilterWrapper from './filterWrapper';
import { estraiFiltri } from '../../graphQL';

const StyledDiv = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.open ? '1.5rem' : 'auto'};
  top: -1rem;
  background: white;
  padding: ${(props) => props.open ? '0 1rem 0 1rem' : '0'};
  cursor: pointer;
`;


const FilterCorsiItalianoComponent = ({
  dataset,
  setFormField,
  onFilterData,
  data,
  setFormFields,
}) => {
  const [open, setOpen] = React.useState(false);
  const [dataFasce, setDataFasce] = React.useState([]);
  const [dataTipoPagamento, setDataTipoPagamento] = React.useState([]);

  const [dataFiltri, getDataFiltri] = useGraphQLRequest(
    undefined,
    estraiFiltri,
    {},
    false,
    );

  React.useEffect(() => {
    if (data?.listCards) {
      const listIdServizioRiferimento = [];
      Object.keys(data?.listCards).map((item) => {
        (data?.listCards[item] || []).map((itemCard) => {
          if (itemCard?.idServizioRiferimento) {
            listIdServizioRiferimento.push(itemCard?.idServizioRiferimento);
          }
        });
      });

      if (listIdServizioRiferimento.length > 0) {
        getDataFiltri({
          listIdServizioRiferimento,
        });
      }
    }

    setFormFields({
      ...dataset,
      ...(data || {}),
    });
  }, [data]);

  React.useEffect(() => {
    if (dataFiltri?.data?.filtriFasceOrarieDaLista && dataFasce.length === 0) {
      setDataFasce(dataFiltri?.data?.filtriFasceOrarieDaLista);
    }

    if (dataFiltri?.data?.filtriTipoPagamentoDaLista && dataTipoPagamento.length === 0) {
      setDataTipoPagamento(dataFiltri?.data?.filtriTipoPagamentoDaLista);
    }
  }, [dataFiltri?.data]);

  return (
    <div style={{ position: 'relative' }}>
      <StyledDiv open={open} onClick={() => setOpen(!open)}>
        <Text weight="bold" size="f5" value="FILTRI DI RICERCA" intlFormatter />
        <span style={{ margin: '0 0 0 0.5rem' }}>
          {
        open ? (
          <FaIcon
            icon="angle-up"
            fontSize="f4"
            title="Chiudi filtri"
            color="primary"
          />
) : (
  <FaIcon
    icon="angle-down"
    fontSize="f4"
    title="Espandi filtri"
    color="primary"
  />
)
      }
        </span>
      </StyledDiv>
      {open ? (
        <FilterWrapper>
          <FormFilterCorsiItaliano
            dataset={dataset}
            setFormField={setFormField}
            onFilterData={onFilterData}
            dataFasce={dataFasce}
            dataTipoPagamento={dataTipoPagamento}
          />
        </FilterWrapper>
) : undefined}
    </div>
  );
};

FilterCorsiItalianoComponent.displayName = 'Accordion Filter Corsi Italiano Component';

const AccordionFilterCorsiItaliano = React.memo(FilterCorsiItalianoComponent);
export default withRouter(AccordionFilterCorsiItaliano);
