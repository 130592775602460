import styled from 'styled-components';
import media from 'utils/media-queries';
import { colors, fonts } from 'theme';
import { Row } from 'components/ui/Grid';

export const Section = styled(Row)`
  padding: 0;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "1em")};
`;

export const CategoryTitle = styled.h2`
  color: ${colors.black};
  font-size: ${fonts.size.f5};
  font-weight: bold;
  text-transform: lowercase;
  ::first-letter {
    text-transform: uppercase;
  }
  ${media.md`
    font-size: ${fonts.size.f4};
  `}
`;
