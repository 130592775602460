import React from 'react';
import Breadcrumbs from 'components/navigation/Breadcrumbs';
import Wrapper from 'components/navigation/NavigationWrapper';
import Stepper from 'components/ui2/Header/Stepper';
import Text from 'components/ui/Text';
import { Column, Row } from 'components/ui/Grid';
import CategorySection from 'components/shared/CategorySection';
import { withRouter } from 'react-router-dom';
import Button from 'components/ui2/Button';
import EntServiceModal from 'components/shared/ModaleServizioEnte';
import { useGraphQLRequest } from 'hooks/graphQLRequest/useGraphQLRequest';
import Loader from 'components/ui2/Loader';
import { BreadcrumbPath } from './constants';
import { estraiCorsiItaliano } from './graphQL';

const CorsiItaliano = withRouter((location) => {
  const [openEntService, setOpenEntService] = React.useState({ open: false, idServizioEnte: undefined });

  const idEnte = React.useMemo(() =>
    location.match.params?.idEnte ? Number.parseInt(location.match.params?.idEnte, 10) : undefined,
  [location.match.params?.idEnte]);

  const idArea = React.useMemo(() =>
    location.match.params?.idArea ? Number.parseInt(location.match.params?.idArea, 10) : undefined,
  [location.match.params?.idArea]);

  const momentoGiornata = React.useMemo(() =>
    location.match.params?.momentoGiornata?.trim() ?
  location.match.params?.momentoGiornata.split(', ')?.map((item) => Number.parseInt(item, 10))
   : [],
  [location.match.params?.momentoGiornata]);

  const prezzo = React.useMemo(() => location.match.params?.prezzo?.trim() ?
  location.match.params?.prezzo.split(', ')?.map((item) => Number.parseInt(item, 10))
   : [], [location.match.params?.prezzo]);

  const municipio = React.useMemo(() => location.match.params?.municipio ?
  Number.parseInt(location.match.params?.municipio, 10) : undefined,
   [location.match.params?.municipio]);

  const [data, getData] = useGraphQLRequest(
        undefined,
        estraiCorsiItaliano,
        {},
        false,
      );

  React.useEffect(() => {
    if (momentoGiornata?.length || prezzo?.length || municipio || idEnte) {
      getData({
        tipoPagamento: prezzo?.length ? prezzo : undefined,
        fasciaOraria: momentoGiornata?.length ? momentoGiornata : undefined,
        idMunicipio: municipio || undefined,
        idEnte: idEnte || undefined,
        idArea: idArea || undefined,
      });
    }
  }, [momentoGiornata, municipio, prezzo]);

  const title = React.useMemo(() =>
    data.data?.length ?
  data.data[0]?.txTitoloArea
  : 'Corsi di lingua italiana',
   [data?.data]);

  const name = React.useMemo(() =>
    data.data?.length ?
  data.data[0]?.nomeEnte
  : 'Nome scuola',
   [data?.data]);

  const media = React.useMemo(() =>
    data.data?.length ?
  data.data[0]?.media
  : '',
   [data?.data]);

  const listServices =
  React.useMemo(() =>
    data.data?.length ?
  data.data.map((item) => ({
    label: item.nomeServizio || item.contenutoNomeDelServizio || '',
    idServizio: item.idServizioEnte,
  }))
  : [],
   [data?.data]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      {data.isLoading ?
        <Loader overlay label="Operazione in corso" /> : (
          <React.Fragment>
            <Breadcrumbs value="Breadcrumb.page" pathSlashes={BreadcrumbPath(name)} ignoreCase />
            <Stepper steps={[{ title: name, active: true, color: 'primary', withoutIndex: true }]} />
            <Row fluid>
              {/* <Column xs="12" padding="0 20px 0 20px">
                <Text
                  tag="span"
                  value={title}
                  weight="bold"
                  margin="0 0 1em 0"
                  size="f4"
                />
              </Column> */}
              <Column xs="12" md="5" padding="0 20px 0 20px">
                <CategorySection
                  categoryName={name}
                  categoryMedia={media}
                  categoryDescription=""
                />
              </Column>
              <Column xs="12" md="7" padding="0 20px 0 20px">
                {listServices.length ? (
                  <Text
                    tag="h3"
                    value="Corsi disponibili"
                    size="f6"
                    weight="bold"
                    transform="uppercase"
                    letterSpacing="0.05em"
                    color="primary"
                  />
                  ) : (
                    <Text
                      tag="p"
                      value="Nessun corso disponibile."
                    />
                  )}
                {listServices.map((item) => (
                  <Button
                    key={`key-${item.label}`}
                    label={item.label}
                    margin="1em 1em 0 0"
                    fontSize="f6"
                    onClick={() => {
                      setOpenEntService({ open: true, idServizioEnte: item.idServizio });
                    }}
                    autowidth
                  />
        ))}
              </Column>
            </Row>
            <EntServiceModal
              open={openEntService.open}
              setOpen={() => {
                setOpenEntService({ open: true, idServizioEnte: openEntService.idServizioEnte });
              }}
              onClose={() => {
                setOpenEntService({ open: false, idServizioEnte: undefined });
              }}
              idEnte={idEnte}
              idServizioEnte={openEntService.idServizioEnte}
            />
          </React.Fragment>
)}
    </Wrapper>
  );
});

CorsiItaliano.displayName = 'CorsiItalianoPage';

export default CorsiItaliano;
