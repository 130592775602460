import { TextSpan } from 'components/navigation/InfoApprendimentoLingua/components.style';
import { Row } from 'components/ui/Grid';
import Text from 'components/ui/Text';
import { BackgroundTitle, bgTitleSizes } from 'components/ui2/BackgroundTitle';
import React from 'react';
import { colors } from 'theme';

const Contatto = () => (
  <div id="contatto">
    <Row fluid>
      <Row fluid margin="2.7em 0 0 0">
        <BackgroundTitle size={bgTitleSizes.small} label="CONTATTO" bgColor="green" />
      </Row>
      <Row fluid margin="1.2em 0 0 0">
        <Text
          value="CONTATTARE IL SERVIZIO PER ESPORRE I PROPRI BISOGNI"
          size="f6"
          weight="bold"
          letterSpacing="0.05em"
          color="green"
          lineHeight="175%"
        />
      </Row>
      <Row fluid>
        <TextSpan>
          Puoi ricevere informazioni contattando telefonicamente o via mail il Servizio. Potrai avere una risposta immediata alle tue domande. In caso sia necessario approfondire la tua richiesta potrai fissare un appuntamento per poter parlare con un operatore specializzato.

          La nostra sede in via Sammartini 75 (
          <a href="https://www.comune.milano.it/aree-tematiche/servizi-sociali/milano-welcome-center" target="_blank">Milano Welcome Center</a>
          &nbsp;per persone migranti e rifugiate) riceve dal lunedì al venerdì dalle 9 alle 17; il numero di telefono è
          <a href="tel:020202" style={{ color: colors.green }}> 02 02 02</a>
          &nbsp;- tasto 4.1 ed è attivo dal lunedì al sabato dalle 8 alle 18; l&apos;indirizzo email è&nbsp;
          <a href="mailto:MilanoWelcomeCenter@comune.milano.it" style={{ color: colors.green }}>
            MilanoWelcomeCenter@
            <wbr />
            comune.milano.it
          </a>
        </TextSpan>
      </Row>
    </Row>
  </div>
);

Contatto.displayName = 'Contatto';
export default Contatto;
