import React from 'react';
import headerImage from 'images2/home-inclusione/homepage-inclusione.jpg';
import { Row, Column } from 'components/ui/Grid';
import Button from 'components/ui2/Button';
import { Form } from 'libs/Form/components/Form';
import { useNavHeight } from 'hooks/useNavHeight';
import yup from 'libs/Form/validation/yup';
import { useGraphQLRequest } from 'hooks/graphQLRequest/useGraphQLRequest';
import { HeaderImageHomeCorsiItaliano, RowSticky } from './index.styled';
import { CategoryChoice, HEADER } from './constants';
import { AccordionFilterCorsiItaliano, ComeContattarci, LeftColumn } from './partials';
import { estraiCorsiItaliano } from './graphQL';
import { transformerCorsiItalianoList } from './utils/transformerCorsiItalianoList';

const initialDataset = {
  selectedChoice: CategoryChoice.Tutti,
  address: '',
  typePayment: [],
  momentDay: [],
  listCards: {},
};

const HomeCorsiItalianoNavigation = () => {
  const navHeight = useNavHeight();

  const [data, getData] = useGraphQLRequest(
      undefined,
      estraiCorsiItaliano,
      {},
      true,
      rs => ({
        ...transformerCorsiItalianoList(rs),
      })
    );

  const onFilterData = (filters) => {
    getData({
      tipoPagamento: filters.typePayment?.length ? filters.typePayment : undefined,
      fasciaOraria: filters.momentDay?.length ? filters.momentDay : undefined,
      idMunicipio: filters.idMunicipio || undefined,
    });
  };

  return (
    <React.Fragment>
      <HeaderImageHomeCorsiItaliano
        imageSrc={headerImage} // TODO cambiare
        titleBgColor="blue"
        descriptionSize="f6"
        titleSize="f5"
        title={HEADER.title}
        description={HEADER.description}
        letterSpacingDescription="0"
        mdIsMobile
        descriptionColumnMd="7"
        descriptionColumnLg="5"
        titleTransform="none"
      />
      <Form
        initialDataset={initialDataset}
        validationSchema={yup.object().shape({})}
        validateOnBlur
        validateOnMount
      >
        {({ dataset, setFormField, setFormFields }) => (
          <Row fluid margin="7rem 0" sizepadding={{ xs: '0 1rem 0 1rem', md: '0 4rem 0 4rem', lg: '0 1rem 0 4rem', xl: '0 4rem 0 4rem' }}>
            <Column xs="12" md="3" lg="3" sizepadding={{ xs: '0 20px 20px 20px', md: '20px' }}>
              <Button
                label="TUTTI"
                onClick={() => setFormField('selectedChoice', CategoryChoice.Tutti)}
                isActive={dataset?.selectedChoice === CategoryChoice.Tutti}
              />
            </Column>
            {
              Object.keys(dataset.listCards).map((item) => (
                <Column xs="12" md="3" lg="3" sizepadding={{ xs: '0 20px 20px 20px', md: '20px' }} key={`key-${item}`}>
                  <Button
                    label={item}
                    ccolor={item === dataset?.selectedChoice ? 'white' : 'primary'}
                    onClick={() => setFormField('selectedChoice', item)}
                    isActive={item === dataset?.selectedChoice}
                  />
                </Column>
                ))
            }
            <Column>
              <AccordionFilterCorsiItaliano
                dataset={dataset}
                setFormField={setFormField}
                onFilterData={onFilterData}
                setFormFields={setFormFields}
                data={data?.data}
              />
            </Column>
            <Column padding="4rem 0 0 0" md="8">
              {/* left */}
              <LeftColumn dataset={dataset} />
            </Column>
            <Column padding="4rem 0 0 0" md="4">
              {/* right */}
              <RowSticky fluid sizepadding={{ xs: '0 0 0 20px', md: '0 0 0 3em', lg: '0 0 0 2em', xl: '0 0 0 10em' }} top={navHeight || 0}>
                <ComeContattarci
                  color="primary"
                />
              </RowSticky>
            </Column>
          </Row>
      )}
      </Form>
    </React.Fragment>
  );
};

HomeCorsiItalianoNavigation.displayName = 'HomeInclusioneComponentNavigation';
export default HomeCorsiItalianoNavigation;
