import React from 'react';
import Text from 'components/ui/Text';
import { Row } from 'components/ui/Grid';
import { BackgroundTitle } from 'components/ui2/BackgroundTitle';
import { bgTitleSizes } from 'components/ui2/BackgroundTitle/constants';
import AnchorLink from 'components/ui/AnchorLink';
import ListaOraria from 'components/shared/ListaOraria';
import { StyledWrapperRaw } from './ComeContattarciConsulenzaGiuridica.styled';
import { colors } from 'theme';

const ComeContattarciConsulenzaGiuridica = ({
  color,
  timetables = [],
}) => (
  <StyledWrapperRaw>
    <BackgroundTitle
      bgColor={color}
      label="COME CONTATTARCI"
      size={bgTitleSizes.small}
    />
    <Row fluid margin="1.8em 0 1.8em">
      <Text
        value={(
          <>
            Hai bisogno di maggiori informazioni o desideri comunicare direttamente con un operatore del
            {' '}
            <a
              href="https://www.comune.milano.it/aree-tematiche/servizi-sociali/milano-welcome-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              Milano Welcome Center
            </a>
            ?
          </>
  )}
        lineHeight="175%"
        size="f7"
      />
    </Row>
    <Row fluid padding="0 0 .5em">
      <Text
        transform="uppercase"
        letterSpacing="0.05em"
        value="CHIAMACI"
        lineHeight="175%"
        size="f7"
      />
    </Row>
    <Row fluid padding="0 0 0.5em">
      <AnchorLink
        weight="bold"
        color={color}
        value="020202"
        to="tel:02 02 02"
        textDecoration="none"
      />
      &nbsp;
      <Text
        value="- tasto 4.1"
        weight="bold"
        color={color}
      />
    </Row>
    <Row fluid padding="0 0 0.5em">
      <Text
        value="Nei seguenti orari:"
        lineHeight="175%"
        size="f7"
      />
    </Row>
    <Row fluid padding="0 0 0.5em">
      <ListaOraria
        timetables={timetables}
      />
    </Row>
    <Row fluid padding="1.8em 0 .5em">
      <Text
        transform="uppercase"
        letterSpacing="0.05em"
        value="scrivici"
        lineHeight="175%"
        size="f7"
      />
    </Row>
    <Row fluid padding="0 0 .5em">
      <AnchorLink
        weight="bold"
        color={color}
        value="Invia la tua richiesta online"
        to="https://servizicrm.comune.milano.it/spec/milanowelcomecenter/servizispecialisticistranieri"
        textDecoration="none"
      />
      <div>
        oppure scrivi a&nbsp;
        <a
          href="mailto:MilanoWelcomeCenter@comune.milano.it"
          style={{ color: colors[color], fontWeight: 'bold', textDecoration: 'none' }}
        >
          MilanoWelcomeCenter@
          <wbr />
          comune.milano.it
        </a>
      </div>
    </Row>
    <Row fluid padding="1.8em 0 .5em">
      <Text
        transform="uppercase"
        letterSpacing="0.05em"
        value="incontraci"
        lineHeight="175%"
        size="f7"
      />
    </Row>
    <Row fluid padding="0">
      <Text
        weight="bold"
        color={color}
        value="Via Sammartini, 75"
        lineHeight="175%"
        size="f7"
      />
    </Row>
    <Row fluid padding="0 1em 0 0">
      <Text
        value="20125 (M1 Rovereto - Bus 87 o 81)"
        lineHeight="175%"
        size="f7"
      />
    </Row>
    <Row fluid padding="0 0 .5em">
      <Text
        value="Da lunedì a venerdì dalle 9.00 alle 17.00"
        lineHeight="175%"
        size="f7"
      />
    </Row>
  </StyledWrapperRaw>
);

ComeContattarciConsulenzaGiuridica.displayName = 'InclusioneComeFunziona - ComeContattarciConsulenzaGiuridica';
export default ComeContattarciConsulenzaGiuridica;
