import { Column, Row } from 'components/ui/Grid';
import InputMatch from 'components/ui2/InputMatch';
import Tooltip from 'components/ui2/Tooltip';
import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { noop } from 'utils/functions/noop';
import Button from 'components/ui2/Button';
import FaIcon from 'components/ui2/FaIcon';
import { useStatelessGraphQLRequest } from 'hooks/graphQLRequest/useStatelessGraphQLRequest';
import { mapAddressCordinatesResponse } from 'components/navigation/HomeSpaziWemi/graphql/mappers';
import styled from 'styled-components';
import { fonts } from 'theme';
import Text from 'components/ui/Text';
import Checkbox from 'components/ui2/Checkbox';
import { getInputAddress, getMunicipio } from '../../graphQL';

const RelativeDiv = styled.div`
  position: relative;
  width: 100%;
  max-width: 26.875rem;

    > div {
      &:first-child {
        width: 100%;
      }
    };

  i {
    position: absolute;
    right: 1em;
    top: calc((100% - ${fonts.size.f7}) / 1.9);
  }
`;

const ColumnButton = styled(Column)`
  display: flex; 
  align-items: flex-end;
`;

const FilterCorsiItalianoComponent = ({
  dataset,
  setFormField,
  onFilterData,
  dataFasce = [],
  dataTipoPagamento = [],
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [limitError, setLimitError] = useState('');
  const [matchedAddress, setMatchedAddress] = useState([]);


  const inputRef = useRef();

  const onInputChange = value => {
    setLimitError('');
    setMatchedAddress([]);
    setFormField('address', value);
    // TODO forse rifare chiamata per resettare
    // Se abbiamo selezinato un indirizzo e cancelliamo il campo il filtro municipio viene resettato
    // if (!value) {
    //   onMunicipalityChange(null);
    // }
  };

  const getMunicipality = useStatelessGraphQLRequest(getMunicipio);

  const handleItemClick = async (item) => {
    setFormField('address', item.value);

    setMatchedAddress([]);
    setDataLoading(true);

    getMunicipality({
      ...item.coordinates,
    }).then(result => {
      setFormField('idMunicipio', result ? result.idMunicipio : undefined);
    })
    .finally(() => setDataLoading(false));
  };

  const getCoordinates = useStatelessGraphQLRequest(
      getInputAddress,
      null,
      mapAddressCordinatesResponse,
      undefined,
      true
    );

  const handleSearch = () => {
    const address = dataset?.address;
    if (address) {
      setDataLoading(true);
      getCoordinates({ address, resultLimit: 3 })
            .then(res => {
              if (res) {
                setMatchedAddress(res);
              }
            })
            .catch(() => setLimitError('Testo troppo generico, affina la ricerca'))
            .finally(() => {
              setDataLoading(false);
              inputRef.current.focus();
            });
    } else {
      // onMunicipalityChange(undefined);
      inputRef.current.focus();
    }
  };


  const checkDisabled = () => {
    if (!dataset?.address) {
      return true;
    }
    if (!dataset?.address?.trim()) {
      return true;
    }
    if (dataset?.address.trim().length < 3) {
      return true;
    }
    return false;
  };

  const handleCheckboxes = (key, value) => {
    let newArr = (dataset[key] || []).slice();

    if (newArr.includes(value)) {
      // eliminare valore
      newArr = newArr.filter((item) => item !== value);
    } else {
      // aggiungere valore
      newArr.push(value);
    }

    setFormField(key, newArr);
  };

  return (
    <Row fluid>
      <Column xs="12" md="4" lg="3">
        <Text
          tag="h3"
          value="Indirizzo"
          transform="uppercase"
          letterSpacing="0.05em"
          weight="bold"
          margin="0 0 1em 0"
          size="f6"
        />
        <RelativeDiv>
          <Tooltip
            position="bottom"
            fontSize="f8"
            color="white"
            bgcolor="blue"
            posAdjustment="10%"
            value="Inserisci il nome della tua via e seleziona il numero civico dal menù a tendina."
          >
            <InputMatch
              minChar={3}
              forwardRef={inputRef}
              color={limitError ? 'red' : 'primary'}
              onChange={onInputChange}
              clickedItem={handleItemClick}
              clickedSelectedItem={noop}
              removedItem={noop}
              matches={matchedAddress}
              loadingMatches={dataLoading}
              label=""
              placeholder="Es.: via Sarpi Paolo"
              inputValue={dataset?.address || ''}
              disabled={dataLoading}
              bgHoverColor="none"
            />
          </Tooltip>
          <button
            type="button"
            disabled={checkDisabled()}
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              padding: 0,
            }}
            onMouseDown={event => event.preventDefault()}
            onClick={() => handleSearch()}
          >
            {
                      dataLoading ?
                        (
                          <div className="fa-3x">
                            <FaIcon
                              fontSize="f7"
                              icon="spinner"
                              customClasses="fa-pulse"
                              color="primary"
                            />
                          </div>
                        ) :
                        (
                          <FaIcon
                            fontSize="f7"
                            icon="arrow-right"
                            color={limitError ? 'red' : 'primary'}
                          />
                        )
                    }
          </button>
        </RelativeDiv>
      </Column>
      <Column xs="12" md="3">
        <Text
          tag="h3"
          value="Momento della giornata"
          transform="uppercase"
          letterSpacing="0.05em"
          weight="bold"
          margin="0 0 1em 0"
          size="f6"
        />
        {dataFasce.map((itemDataFasce) => (
          <Row fluid key={itemDataFasce.id.toString()}>
            <Checkbox
              value={dataset?.momentDay?.includes(itemDataFasce.id)}
              onChange={() => { handleCheckboxes('momentDay', itemDataFasce.id); }}
              label={itemDataFasce.label}
              labelledby={itemDataFasce.label}
              fontSize="f7"
              checkcolor="primary"
              width="auto"
            />
          </Row>
      ))}
      </Column>
      <Column xs="12" md="3">
        <Text
          tag="h3"
          value="Prezzo"
          transform="uppercase"
          letterSpacing="0.05em"
          weight="bold"
          margin="0 0 1em 0"
          size="f6"
        />
        {dataTipoPagamento.map((itemDataTipoPagamento) => (
          <Row fluid key={itemDataTipoPagamento.id.toString()}>
            <Checkbox
              value={dataset?.typePayment?.includes(itemDataTipoPagamento.id)}
              onChange={() => { handleCheckboxes('typePayment', itemDataTipoPagamento.id); }}
              label={itemDataTipoPagamento.label}
              labelledby={itemDataTipoPagamento.label}
              fontSize="f7"
              checkcolor="primary"
              width="auto"
            />
          </Row>
      ))}
      </Column>
      <ColumnButton xs="12" md="2">
        <Button color="blue" label="cerca" onClick={() => onFilterData(dataset)} />
      </ColumnButton>
    </Row>
  );
};

FilterCorsiItalianoComponent.displayName = 'Form Filter Corsi Italiano Component';

const FormFilterCorsiItaliano = React.memo(FilterCorsiItalianoComponent);
export default withRouter(FormFilterCorsiItaliano);
