import { PAGE_HOME_URL, PAGE_HOME_CORSI_ITALIANO, PAGE_CORSI_ITALIANO_SERVIZI } from 'types/url';

export const BreadcrumbPath = (name) => [
  {
    slash: 'Home',
    url: PAGE_HOME_URL,
  },
  {
    slash: 'WeMi scuole',
    url: PAGE_HOME_CORSI_ITALIANO,
  },
  {
    slash: name.toUpperCase(),
    url: PAGE_CORSI_ITALIANO_SERVIZI,
  },
];