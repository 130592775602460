const transformerListCard = (item) => ({
  idEnte: item.idEnte,
  name: item.nomeEnte || '',
  isPartner: item.fgPartnerCdMi === '1',
  address: item.indirizzo || 'Indirizzo',
  number: item.telefono || '',
  email: item.email || '',
  idServizioRiferimento: item.idServizioRiferimento,
  idArea: item.idArea,
});

export const transformerCorsiItalianoList = (data = []) => ({ listCards: {
  ...data.reduce((acc, item) => {
    if (!acc[item.txTitoloArea]) {
      acc[item.txTitoloArea] = [];
    }
    acc[item.txTitoloArea].push(transformerListCard(item));
    return acc;
  }, {}),
} });
