export const estraiCorsiItaliano = [
  '',
  `query estraiCorsiItaliano ($tipoPagamento: [Int], $fasciaOraria: [Int], $idMunicipio: Int, $idEnte: Int, $idArea: Int) {
          estraiCorsiItaliano (tipoPagamento: $tipoPagamento, fasciaOraria: $fasciaOraria, idMunicipio: $idMunicipio, idEnte: $idEnte, idArea: $idArea) {
            idEnte
            nomeEnte
            txTitoloServizio
            nomeServizio
            idServizioEnte
            txTitoloArea
            media
            contenutoNomeDelServizio
          }
        }
      `,
  'estraiCorsiItaliano',
];
