/** @format */

import styled from 'styled-components';

const FilterWrapper = styled.div`
    width:100%;
    border: 1.5px solid ${({ theme }) => theme.colors.blue};
    border-radius: 0 0 5px 5px;
`;

FilterWrapper.displayName = 'FilterWrapper';

export default FilterWrapper;
