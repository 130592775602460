import styled from 'styled-components';
import media from 'utils/media-queries';
import { colors } from 'theme';

export const CategoryLogo = styled.img`
  display: block;
  width: 70%;
  height: 70%;
  margin-top: 0;
  ${media.md`
    width: 80%;
    margin-right: auto;
    margin-left: 0;
    margin-top: 0;
  `}
`;

export const CategoryDescription = styled.p`
  color: ${colors.black};
  margin: 1em 0;
  padding-right: 0;
  padding: 0;
  width: 100%;
  ${media.md`
  padding-right: 3rem;
`}
`;
