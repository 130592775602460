export const serviziTCB = [
  {
    title: 'ORIENTAMENTO E CONSULENZA',
    value:
      'Lo sportello assistenti familiari orienta i cittadini lavoratori ai servizi disponibili sul territorio in tema di ricerca di lavoro, contratti di lavoro domestico e opportunità di formazione professionali per baby sitter, colf e badanti.',
  },
  // {
  //   title: 'TUTORING',
  //   value: "Per affrontare questioni legate alla relazione con l'assistito e la sua famiglia o alla gestione del rapporto di lavoro grazie alla mediazione di un nostro operatore, presso il tuo domicilio o presso la sede di WeMi Tate Colf Badanti.",
  // },
  {
    title: 'REGISTRO TERRITORIALE DEGLI ASSISTENTI FAMILIARI DI REGIONE LOMBARDIA',
    value:
      'Assistenza all’iscrizione al Registro Territoriale degli assistenti familiari di Regione Lombardia che certifica le proprie competenze lavorative e permette al datore di lavoro di accedere a misure di sostegno economico.',
  },
];
