export const estraiCorsiItaliano = [
  '',
  `query estraiCorsiItaliano ($tipoPagamento: [Int], $fasciaOraria: [Int], $idMunicipio: Int) {
        estraiCorsiItaliano (tipoPagamento: $tipoPagamento, fasciaOraria: $fasciaOraria, idMunicipio: $idMunicipio) {
          idEnte
          nomeEnte
          email
          telefono 
          indirizzo 
          fgPartnerCdMi
          txTitoloArea
          idServizioRiferimento
          idArea
        }
      }
    `,
  'estraiCorsiItaliano',
];

export const estraiFiltri = [
  '',
  `query ($listIdServizioRiferimento: [Int]) {
       filtriFasceOrarieDaLista (listIdServizioRiferimento: $listIdServizioRiferimento) {
          id
          label
        } 
        filtriTipoPagamentoDaLista (listIdServizioRiferimento: $listIdServizioRiferimento) {
          id
          label
        } 
      }
    `,
  '',
];

export const getMunicipio = [
  '',
  `
    query getMunicipio(
      $pointX: Float!,
      $pointY: Float!
    ) {
        cercaMunicipio(coordinate:{pointX: $pointX, pointY: $pointY}) {
            idMunicipio
            nmMunicipio
        }
    }`,
  'cercaMunicipio',
];

export const getInputAddress = [
  '',
  `
    query (
      $address: String!,
      $resultLimit: Int!
    ) {
        cercaVia(via: $address, resultLimit: $resultLimit) {
            pointX
            pointY
            name
        }
    }`,
  'cercaVia',
];
