import React from 'react';
import { Helmet } from 'react-helmet';
import HomeCorsiItalianoNavigation from 'components/navigation/HomeCorsiItaliano';

const HomeCorsiItaliano = () => {
  const title = 'WeMi - Home Corsi Italiano';
  const description = 'Home Corsi Italiano nella quale è possibile consultare diversi corsi';
  const keywords = 'welfare, milano, servizi, servizio, ente, enti, CORSI DI ITALIANO, CORSI ITALIANO, ITALIANO';


  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <HomeCorsiItalianoNavigation />
    </React.Fragment>
  );
};

HomeCorsiItaliano.displayName = 'HomeCorsiItalianoComponentPage';
export default HomeCorsiItaliano;
